
<div class="menus-container">
    <app-myspice-nav-bar></app-myspice-nav-bar><br><br><br><Br><br><br><br>
    <div class="title-container">
        <h3>Our next menu is currently under construction.<br><br>Please check out our past menus below!</h3>
    </div>
    <div class="gallery-container">
        <div class="gallery-item" *ngFor="let image of pastMenus">
            <img [src]="image" alt="Past Menu">
        </div>
    </div>
</div>

