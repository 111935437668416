<nav class="navbar">
  <div class="navbar-logo">
    <img src="assets/Scampi_Logo_MulticolorMark_Black.PNG" alt="Scampi Logo" />
  </div>
  <ul class="navbar-links">
    <li><a routerLink="/myspice">Home</a></li>
    <li><a href="https://www.opentable.com/r/scampi-philadelphia" target="_blank">Reservations</a></li>
    <li><a routerLink="/beverage">Beverage</a></li>
    <li><a routerLink="/menu">Menu</a></li>
    <li><a href="https://scampiphilly.substack.com" target="_blank">News</a></li>
  </ul>
  <div class="navbar-actions">
    <!-- <input type="email" [(ngModel)]="email" style="font-size:1vh; padding:1vh; width:25vw; margin-right:1vw;" placeholder="Enter your email to subscribe to our mailing list!" required />
    <button (click)="onSubscribe()" style="margin-right:1vw;">Subscribe</button>
    <p style="color:#fbb199; font-size: 1vh" *ngIf="message">{{ message }}</p> -->
  </div>
  <div class="dropdown">
    <button [ngClass]="buttonClass" (click)="toggleMenu()" (touchstart)="changeColor(true)" 
          (touchend)="changeColor(false)" class="dropdown-button">
        <img src="assets/DropdownButton.PNG" alt="Menu Dropdown Button" />
    </button>
    <div *ngIf="isOpen" class="dropdown-content">
      <a routerLink="/myspice">Home</a><br><br>
      <a href="https://www.opentable.com/r/scampi-philadelphia" target="_blank">Reservations</a><br><br>
      <a routerLink="/beverage">Beverage</a><br><br>
      <a routerLink="/menu">Menu</a><br><br>
      <a href="https://scampiphilly.substack.com" target="_blank">News</a>
    </div>
  </div>
</nav>  