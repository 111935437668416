import { Component, OnInit, HostListener, NgZone, ChangeDetectorRef } from '@angular/core';
import { PageStateService } from '../page-state.service';
import { ScoreService } from '../score.service';
import { windowToggle } from 'rxjs/operators';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css']
})
export class ScoreComponent implements OnInit {

  scoreStyle: any;

  constructor(public score: ScoreService, private state: PageStateService, private ngZone: NgZone, private cdr: ChangeDetectorRef) { }

  setScoreStyle() {
    var textShadow = window.innerWidth * 0.65/512;
    this.scoreStyle.left = window.innerWidth * 210/512 + "px";
    this.scoreStyle.top = window.innerWidth * 225/512 + "px";
    this.scoreStyle.fontSize = window.innerWidth * 9/512 + "px";

    if (window.innerWidth/window.innerHeight < 0.65) {
      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.userAgent.includes("Macintosh") && navigator.maxTouchPoints > 1);

      if (isMobile) {
        this.scoreStyle.left = window.innerWidth * 185/306 + "px";
        this.scoreStyle.top = window.innerWidth * 370/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
        this.scoreStyle.fontSize = window.innerWidth *  10/306 + "px";
        textShadow = window.innerWidth * 0.65/306;
      }
    } else if (window.innerWidth/window.innerHeight < 0.95) {
      this.scoreStyle.left = window.innerWidth * 65/512 + "px";
      this.scoreStyle.top = window.innerWidth * 357/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.scoreStyle.fontSize = window.innerWidth *  10/306 + "px";
      textShadow = window.innerWidth * 0.65/306;

    } else if (window.innerWidth/window.innerHeight < 1.12) {
      this.scoreStyle.left = window.innerWidth * 90/512 + "px";
      this.scoreStyle.top = window.innerHeight * 225/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 9/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    } else if (window.innerWidth/window.innerHeight < 1.44) {
      this.scoreStyle.left = window.innerWidth * 175/512 - this.state.getLeftCutoff() + "px";
      this.scoreStyle.top = window.innerHeight * 225/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 9/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    } else if (this.state.isNarrow()) {
      this.scoreStyle.left = window.innerHeight * 210/320 - this.state.getLeftCutoff() + "px";
      this.scoreStyle.top = window.innerHeight * 225/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 9/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    }


    this.scoreStyle.textShadow = '-' + textShadow + 'px -' + textShadow + 'px 0 #000, ' + textShadow + 'px -' + textShadow + 'px 0 #000, -' + textShadow + 'px ' + textShadow + 'px 0 #000, ' + textShadow + 'px ' + textShadow + 'px 0 #000';
  }

  getScore() {
    this.cdr.detectChanges();
    this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.getScore()));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScoreStyle();
  }

  ngOnInit(): void {
    this.scoreStyle = {};

    this.setScoreStyle();
    this.getScore();
  }

}
