import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { HighScoreService } from '../high-score.service';
import { PageStateService } from '../page-state.service';

@Component({
  selector: 'app-high-scores',
  templateUrl: './high-scores.component.html',
  styleUrls: ['./high-scores.component.css']
})
export class HighScoresComponent implements OnInit {

  highScoreText: string;
  highScores: any[];

  divTransform: string;
  divFontSize: string;
  divLineHeight: string;
  divLetterSpacing: string;
  divHeight: string;
  divWidth: string;
  divLeft: string;
  divTop: string;
  textShadow: number;

  headingStyle: any;

  constructor(private highScoreService: HighScoreService, private cdr: ChangeDetectorRef, private state: PageStateService) { }

  setHighScoreDivSize() {
    var aspectRatio = window.innerWidth / window.innerHeight;
    var narrow = aspectRatio < 512/320;
    this.textShadow = window.innerWidth * 0.6/512;
    this.divFontSize = 16 * window.innerWidth * 190/512/413 + "px";
    this.divLineHeight = 12 * window.innerWidth * 190/512/413 + "px";
    this.divHeight = window.innerWidth * 70/512 + "px";
    this.divWidth = window.innerWidth * 72/512 + "px";
    this.divLeft = window.innerWidth * 412/512 + "px";
    this.divTop = window.innerWidth * 73/512 + "px";
    this.divTransform = `perspective(15cm) rotateX(-18deg) rotateY(-45deg) scale(1)`;
    this.divLetterSpacing = 1 * window.innerHeight * 190/320/413 + "px";
    this.headingStyle.visibility = "hidden";
    if (narrow) {
      this.divLeft = window.innerHeight * 412/320 - this.state.getLeftCutoff() + "px";
      this.divTop = window.innerHeight * 73/320 + "px";
      this.divHeight = window.innerHeight * 70/320 + "px";
      this.divWidth = window.innerHeight * 72/320 + "px";
      this.divFontSize = 16 * window.innerHeight * 190/320/413 + "px";
      this.divLineHeight = 12 * window.innerHeight * 190/320/413 + "px";
      this.textShadow = window.innerHeight * 0.6/320;
    }
    if (aspectRatio < 1.44) {
      this.divHeight = window.innerHeight * 70/320 + "px";
      this.divTop = window.innerHeight * 244/320 + "px";
      this.divTransform = "none";
      this.divLetterSpacing = 5 * window.innerHeight * 190/320/413 + "px";
      this.headingStyle.visibility = "visible";
      this.headingStyle.height = window.innerHeight * 15/320 + "px";
      this.headingStyle.top = window.innerHeight * 222/320 + "px";
      this.headingStyle.width = parseInt(this.headingStyle.height,10) * 164/22 + "px";
      this.divWidth = this.headingStyle.width;
      this.divLeft = window.innerWidth * 320/512 + "px";
      // this.divLeft = window.innerWidth/2 - parseInt(this.divWidth,10)/2 + "px";
      this.headingStyle.left = this.divLeft;
    }
    if (aspectRatio < 1.12) {
      this.divLeft = 0.75*window.innerWidth - parseInt(this.divWidth,10)/2 + "px";
      this.headingStyle.left = this.divLeft;
    } 
    if (aspectRatio < 0.95) {
      this.divTop = window.innerWidth * 376/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.divFontSize = 16 * window.innerWidth * 190/306/413 + "px";
      this.divLineHeight = 12 * window.innerWidth * 190/320/413 + "px";
      this.headingStyle.height =  window.innerWidth * 15/306 + "px";
      this.headingStyle.top = window.innerWidth * 352/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.headingStyle.width = parseInt(this.headingStyle.height,10) * 164/22 + "px";
      this.divWidth = this.headingStyle.width;
      this.divLeft = 0.75*window.innerWidth - parseInt(this.divWidth,10)/2 + "px";
      this.headingStyle.left = this.divLeft;
      this.textShadow = window.innerWidth * 0.65/306;
    }
    if (aspectRatio < 0.65) {
      // this.divTop = window.innerWidth * 376/306*(1 + 0.225*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      // this.headingStyle.height = window.innerWidth * 15/306*(1 + 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      // this.headingStyle.width = parseInt(this.headingStyle.height,10) * 164/22 + "px";
      // this.divWidth = this.headingStyle.width;

      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.userAgent.includes("Macintosh") && navigator.maxTouchPoints > 1);

      // if (isMobile) {
      //   this.divLeft = 0.35*window.innerWidth - parseInt(this.divWidth,10)/2 + "px";
      // } else {
      //   this.divLeft = 0.5*window.innerWidth - parseInt(this.divWidth,10)/2 + "px";
      // }

      if (isMobile) {
        this.divLeft = 0.1*window.innerWidth + "px";
        this.divTop = window.innerWidth * 380/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
        this.headingStyle.top = window.innerWidth * 356/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
        this.divFontSize = 16.5 * window.innerWidth * 190/306/413 + "px";
        this.headingStyle.left = this.divLeft;
      }
      
      // this.divFontSize = 16 * window.innerWidth * 190/306/413*(1 + 0.7*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      // this.divLineHeight = 12 * window.innerWidth * 190/320/413*(1 + 0.7*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      // this.headingStyle.left = this.divLeft;
    }
  
    this.cdr.detectChanges();
  }

  setHighScoreText() {
    this.highScoreService.getHighScores().subscribe((data: any[]) => {
      data.sort((a, b) => {
        return b.score - a.score;
      });
      this.highScores = data;
      this.highScoreText = "";
      for (let i = 0; i < 6; i++) {
          this.highScoreText += this.highScores[i].name + "-" + this.highScores[i].score + "<br><br>";
      }
      this.cdr.markForCheck();
    },
    (error) => {
      console.error('Error:', error);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setHighScoreDivSize();
    this.cdr.detectChanges();
  }

  ngOnInit(): void { 
    // this.divTransform = `perspective(15cm) rotateX(-18deg) rotateY(-45deg)`;
    this.headingStyle = {};
    this.headingStyle.backgroundImage = `url("assets/kitchen/HighScores.PNG")`;
    this.headingStyle.backgroundSize = "cover";
    this.setHighScoreDivSize();
    this.setHighScoreText();
    this.highScoreService.registerHighScoresComponent(this);
  }

}
