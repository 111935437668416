import { Component, OnInit } from '@angular/core';
import { BrevoService } from '../brevo.service';

@Component({
  selector: 'app-myspice-nav-bar',
  templateUrl: './myspice-nav-bar.component.html',
  styleUrls: ['./myspice-nav-bar.component.css']
})
export class MyspiceNavBarComponent implements OnInit {
  email: string = '';
  message: string = '';

  buttonClass = 'default';

  isOpen = false;

  constructor(private brevoService: BrevoService) { }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  changeColor(isPressed: boolean) {
    this.buttonClass = isPressed ? 'pressed' : 'default';
    console.log('press')
  }

  onSubscribe() {
    this.brevoService.subscribe(this.email).subscribe(
      response => {
        this.message = 'Thank you for subscribing!';
        this.email = '';
      },
      error => {
        this.message = 'An error occured. Please try again.';
        console.error(error);
      }
    )
  }

  ngOnInit(): void {
  }

}
