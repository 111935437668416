import { Component, OnInit, HostListener } from '@angular/core';
import { PageStateService } from '../page-state.service';
import { ScoreService } from '../score.service';

@Component({
  selector: 'app-game-over',
  templateUrl: './game-over.component.html',
  styleUrls: ['./game-over.component.css']
})
export class GameOverComponent implements OnInit {

  gameOverStyle: any;
  scoreStyle: any;
  restartStyle: any;
  containerStyle: any;

  isMobile: boolean;

  constructor(public score: ScoreService, private state: PageStateService) { }

  setGameOverStyle() {
    var textShadow = window.innerWidth * 0.65/512;
    this.containerStyle.left = window.innerWidth * 165/512 + "px";
    this.containerStyle.top = window.innerWidth * 175/512 + "px";
    this.containerStyle.lineHeight = window.innerWidth * 3/512 + "px";
    this.gameOverStyle.fontSize = window.innerWidth * 12/512 + "px";
    this.gameOverStyle.lineHeight = window.innerWidth * 10/512 + "px";
    this.scoreStyle.fontSize = window.innerWidth * 7/512 + "px";
    this.restartStyle.fontSize = window.innerWidth * 4.5/512 + "px";

    if (window.innerWidth/window.innerHeight < 0.95) {
      this.containerStyle.left = window.innerWidth * 30/306 + "px";
      this.containerStyle.top = window.innerWidth * 355/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.containerStyle.lineHeight = window.innerWidth * 3.5/306 + "px";
      this.gameOverStyle.fontSize = window.innerWidth * 12/306 + "px";
      this.gameOverStyle.lineHeight = window.innerWidth * 10/306 + "px";
      this.scoreStyle.fontSize = window.innerWidth * 7/306 + "px";
      this.restartStyle.fontSize = window.innerWidth * 4.5/306 + "px";
      textShadow = window.innerWidth * 0.65/306;

      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.userAgent.includes("Macintosh") && navigator.maxTouchPoints > 1);

      if (isMobile) {
        this.containerStyle.left = window.innerWidth * 166/306 + "px";
        this.containerStyle.top = window.innerWidth * 358/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
        this.containerStyle.lineHeight = window.innerWidth * 5/306 + "px";
        this.gameOverStyle.fontSize = window.innerWidth * 14/306 + "px";
        this.scoreStyle.fontSize = window.innerWidth * 9/306 + "px";
        this.restartStyle.fontSize = window.innerWidth * 6/306 + "px";
      }
    } else if (window.innerWidth/window.innerHeight < 1.12) {
      this.containerStyle.left = window.innerWidth * 70/512 + "px";
      this.containerStyle.top = window.innerHeight * 225/320 + "px";
      this.containerStyle.lineHeight = window.innerHeight * 3.5/320 + "px";
      this.gameOverStyle.fontSize = window.innerHeight * 12/320 + "px";
      this.gameOverStyle.lineHeight = window.innerHeight * 10/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 7/320 + "px";
      this.restartStyle.fontSize = window.innerHeight * 4.5/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    } else if (window.innerWidth/window.innerHeight < 1.44) {
      this.containerStyle.left = window.innerWidth * 110/512 + "px";
      this.containerStyle.top = window.innerHeight * 225/320 + "px";
      this.containerStyle.lineHeight = window.innerHeight * 3.5/320 + "px";
      this.gameOverStyle.fontSize = window.innerHeight * 12/320 + "px";
      this.gameOverStyle.lineHeight = window.innerHeight * 10/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 7/320 + "px";
      this.restartStyle.fontSize = window.innerHeight * 4.5/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    } else if (this.state.isNarrow()) { 
      this.containerStyle.left = window.innerHeight * 170/320 - this.state.getLeftCutoff() + "px";
      this.containerStyle.top = window.innerHeight * 225/320 + "px";
      this.containerStyle.lineHeight = window.innerHeight * 3.5/320 + "px";
      this.gameOverStyle.fontSize = window.innerHeight * 12/320 + "px";
      this.gameOverStyle.lineHeight = window.innerHeight * 10/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 7/320 + "px";
      this.restartStyle.fontSize = window.innerHeight * 4.5/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    }

    this.containerStyle.textShadow = '-' + textShadow + 'px -' + textShadow + 'px 0 #000, ' + textShadow + 'px -' + textShadow + 'px 0 #000, -' + textShadow + 'px ' + textShadow + 'px 0 #000, ' + textShadow + 'px ' + textShadow + 'px 0 #000';
  } 

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGameOverStyle();
  }

  ngOnInit(): void {
    this.gameOverStyle = {};
    this.scoreStyle = {};
    this.restartStyle = {};
    this.containerStyle = {};
    this.gameOverStyle.color = "#f5693d";
    this.scoreStyle.color = "rgba(99,199,77,1)";
    this.restartStyle.color = "rgba(99,199,77,1)";

    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    this.setGameOverStyle();
  }

}
