import { Component, OnInit, ChangeDetectorRef, HostListener, Renderer2 } from '@angular/core';
import { PageStateService, PageState } from '../page-state.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  counterStyle: any;
  counterTopStyle: any;
  backgroundImage: string;
  backgroundPosition: string;

  public PageState = PageState;

  constructor(public state: PageStateService, private cdr: ChangeDetectorRef, private renderer: Renderer2){}

  chooseBackgroundImage() {
    let aspectRatio = window.innerWidth/window.innerHeight;
    if (this.state.currentState === PageState.ABOUT) { 
      this.backgroundImage = `url("assets/AboutUsBackground.PNG")`;
    } else if (aspectRatio < 0.95) {
      this.counterStyle.visibility = "visible";
      this.counterTopStyle.visibility = "visible";
      this.backgroundImage = `url("assets/kitchen/NewKitchenBase.PNG")`;
      this.counterStyle.backgroundImage = `url("assets/kitchen/KitchenCounter.PNG")`;
      this.counterStyle.backgroundSize = window.innerWidth*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) + "px auto";
      this.counterTopStyle.backgroundImage = `url("assets/kitchen/CounterTop.PNG")`;
      this.counterTopStyle.width = window.innerWidth * 299/306 + "px";
      this.counterTopStyle.height = window.innerWidth * 56/306 + "px";
      this.counterTopStyle.top = window.innerWidth * 276/306*(1 + 0.22*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.counterTopStyle.left = window.innerWidth * 3/306 + "px";
      // this.backgroundPosition = "center " + -100*(1 - (window.innerHeight*window.innerWidth/306 - 306)/356)/662 * window.innerHeight;
      if ((window.innerHeight/window.innerWidth*306 - 306)/356 < 1) {
        this.backgroundPosition = "center " + -this.state.getTopCutoff() + "px";
        this.counterStyle.backgroundPosition = "center " + -this.state.getCounterTopCutoff() + "px";
      } else {
        this.backgroundPosition = "top";
        this.counterStyle.backgroundSize = window.innerHeight*1.2*306/662 + "px auto";
        this.counterStyle.backgroundPosition = "center " + window.innerHeight*-0.45*126/662 + "px";
      }
    } else {
      this.counterStyle.visibility = "hidden";
      this.counterTopStyle.visibility = "hidden";
      if (aspectRatio < 1.44) {
        this.backgroundImage = `url("assets/kitchen/Kitchen_24_Narrow.PNG")`
      } else {
        this.backgroundImage = `url("assets/kitchen/Kitchen_24.PNG")`;
      }
      // this.backgroundImage = `url("assets/kitchen/Kitchen_24.PNG")`;
      
      if (this.state.isNarrow()) {
        this.backgroundPosition = -this.state.getLeftCutoff() + "px top";
      } else {
        this.backgroundPosition = "top";
      }
      
    }
    this.cdr.detectChanges();
  }

  getTextShadow() {
    // const body = this.renderer.selectRootElement('body');
    // var shadowSize = window.innerWidth * 2/512;
    // if (window.innerWidth/window.innerHeight < 0.95) {
    //   shadowSize = window.innerWidth * 2/306;
    // } else if (this.state.isNarrow()) {
    //   shadowSize = window.innerHeight * 2/320;
    // }
    // console.log(shadowSize);
    // this.renderer.setStyle(body, 'text-shadow', `-${shadowSize}px -${shadowSize}px 0 #fff, ${shadowSize}px -${shadowSize}px 0 #fff, -${shadowSize}px ${shadowSize}px 0 #fff, ${shadowSize}px ${shadowSize}px 0 #fff`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.chooseBackgroundImage();
    this.getTextShadow();
  }

  ngOnInit() {
    this.counterStyle = {};
    this.counterTopStyle = {};
    this.chooseBackgroundImage();
    this.state.getCurrentStateObservable().subscribe(state => {
      this.chooseBackgroundImage();
      this.cdr.detectChanges();
    });
  }

}
