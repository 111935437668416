<div class="bg-container" [ngStyle]="{'background-image':backgroundImage, 'background-position':backgroundPosition}">
    <app-menu-buttons *ngIf="state.currentState !== PageState.ABOUT"></app-menu-buttons>
    <app-menu-cards id="menuCards" *ngIf="state.currentState === PageState.PAST"></app-menu-cards>
    <!-- <app-our-story-book *ngIf="state.currentState === PageState.ABOUT"></app-our-story-book> -->
    <!-- <app-my-space-page *ngIf="state.currentState === PageState.ABOUT"></app-my-space-page> -->
    <app-future-dates-calendar *ngIf="state.currentState === PageState.FUTURE"></app-future-dates-calendar>
    <app-high-scores *ngIf="state.currentState !== PageState.ABOUT"></app-high-scores>
    <app-frog-game *ngIf="state.currentState !== PageState.ABOUT"></app-frog-game>
    <!-- <div class="bg-counter-top" [ngStyle]="counterTopStyle"></div> -->
    <div *ngIf="state.currentState !== PageState.ABOUT" class="bg-front-container" style="z-index:3" [ngStyle]="counterStyle"></div>
</div>
