import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrevoService {

  private apiKey = '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';

  constructor(private http: HttpClient) { }

  subscribe(emailInput: string): Observable<any> {
    const url = 'https://api.brevo.com/v3/contacts';
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'content-type': 'application/json',
      'api-key': this.apiKey
    });

    const body = {
      email: emailInput
    };
    
    return this.http.post(url, body, { headers });
  }
}
