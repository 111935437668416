<app-myspice-nav-bar></app-myspice-nav-bar>
<div class="shrimp-container">
    <img src="assets/SheRimp.GIF" alt="Dancing Shrimp" class="profile-shrimp" />
    <img src="assets/SheRimp.GIF" alt="Dancing Shrimp" class="blurb-shrimp-1" />
    <img src="assets/SheRimp.GIF" alt="Dancing Shrimp" class="blurb-shrimp-2" />
    <img src="assets/SheRimp.GIF" alt="Dancing Shrimp" class="contact-shrimp-1" />
    <img src="assets/SheRimp.GIF" alt="Dancing Shrimp" class="contact-shrimp-2" />
    <img src="assets/SheRimp.GIF" alt="Dancing Shrimp" class="contact-shrimp-3" />
</div>
<div class="profile">
    <div class="left-column">
        <div class="photo-block">
            <div class="photo-info-block">
                <h2>{{ profileName }}</h2>
                <img src="/assets/Liz.PNG" alt="Profile Photo">
                <p class="mood">Mood: hungry</p>
            </div>
            <div class="details-block">
                <p>She/Rimp</p>
                <p>30 years old</p>
                <p>Philadelphia</p>
                <p>United States</p>
            </div>
        </div>
        
        <div class="contact-block">
            <h2>Contact {{ profileName }}</h2>
            <p>Email: <a href="#" id="copyLink">BossHog@ScampiPhilly.com</a></p>
            <p>Instagram: <a href="https://www.instagram.com/Scampi_Philly" target="_blank">@Scampi_Philly</a></p>
            <p>Funsite: <a href="https://lizlaugh.love" target="_blank">lizlaugh.love</a></p>
            <p>Address: <a href="https://maps.app.goo.gl/7EegMxzRUucYbYVx5" target="_blank">617 S Third Street, Philadelphia, PA 19147</a></p>
        </div>
    </div>
	<div class="right-column">
        <div class="profile-block">
            <h2>{{ profileName }} is {{ profileName }}</h2>
        </div>
        
        <div class="about-block">
            <h2>{{ profileName }}'s Blurbs:</h2>
            <h3>About Me:</h3>
            <p>Owner and head chef of Couch Cafe. <br>Running on caffeine and the bit.<br><br>I got two shrimps inside me...<br>and they won't stop COOKIN'</p>
            <h3>Who I'd Like to Meet:</h3>
            <p>Matty Matheson</p>
        </div>

        <div class="top-friends-block">
            <div class="top-friends-title">
                <h2>{{ profileName }}'s Top Friends:</h2>
            </div>
            <div class="friend-cards-container">
                <div class="friend-card" *ngFor="let friend of friends">
                    <img [src]="friend.image" alt="{{ friend.name }}" class="friend-image" />
                    <p class="friend-name">{{ friend.name }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
  